* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(33, 37, 41);
}

a {
  text-decoration: none;
  color: white;
}
