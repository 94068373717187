.about-container {
  box-sizing: border-box;
  margin-top: 0.5rem;
  padding: 1rem;
}

.contact {
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.contact > div {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  border: 1px solid #484848;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.contact > div > i {
  font-size: 1.25rem;
}

#email:hover {
  color: #ea4335;
  cursor: pointer;
}

#github:hover {
  color: #34a853 !important;
  cursor: pointer;
}

p {
  margin: 0;
  margin-top: 1rem;
}

.about-header {
  box-sizing: border-box;
  display: inline-block;
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #fbbc05;
}

#skills-container {
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

#skills-container > li {
  list-style: none;
  box-sizing: border-box;
  border: 1px solid #484848;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}

@media (max-width: 1400px) {
  .contact {
    flex-wrap: wrap;
  }
}
