main {
  box-sizing: border-box;
  padding: 1rem;
  color: white;
  margin: 0 20%;
  border: 1px solid #484848;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-bottom: 1rem;
}

.header {
  font-size: 1.25rem;
  color: #fbbc05;
  color: #4285f4;
  border-bottom: 1px solid #fbbc05;
  border-bottom: 1px solid #484848;
  padding: 0.5rem;
  padding-left: 0;
  padding-bottom: 0.2rem;
  margin-left: 1rem;
  width: fit-content;
}

footer {
  box-sizing: border-box;
  text-align: center;
  padding: 1rem;
  padding-bottom: 0;
  color: #484848;
}

@media (max-width: 750px) {
  main {
    margin: 0;
    border: none;
    border-top: 1px solid #484848;
  }
}
